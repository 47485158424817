export default {
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerkingen Opslaan"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplaan..."])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je het zeker?"])},
  "copy-id-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Kopiëren"])},
  "time": {
    "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vandaag om ", _interpolate(_list(0))])},
    "yesterday-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Gisteren om ", _interpolate(_list(0))])}
  },
  "message-area": {
    "no-message-perm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt geen toestemming om in dit kanaal te praten."])},
    "not-connected-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet Verbonden"])},
    "attach-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een bericht toevoegen"])},
    "type-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ je bericht"])},
    "back-to-bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug Naar Onder"])},
    "new-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe Berichten"])}
  },
  "upload-dialog": {
    "compress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimeren"])},
    "upload-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Naar"])}
  },
  "user-context": {
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk Profiel"])},
    "edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen Bewerken"])},
    "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
    "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
    "copy-user-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer User:Tag"])}
  },
  "message-context": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citeer"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])}
  },
  "server-context": {
    "mark-as-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeer Als Gelezen"])},
    "server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Instellingen"])},
    "leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Verlaten"])}
  },
  "channel-context": {
    "mute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal Dempen"])},
    "unmute-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal Ondempen"])}
  },
  "settings": {
    "tab-names": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaties"])},
      "manage-emojis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis beheren"])},
      "startup-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opstart Opties"])},
      "program-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programma Activiteiten"])},
      "manage-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots beheren"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
      "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Verwijderen"])},
      "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigings log"])},
      "wip-features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIP Features"])}
    },
    "account": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel Bewerken"])},
      "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord Veranderen"])},
      "relink-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Drive Opnieuw Koppelen"])},
      "link-google-drive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Drive Koppelen"])},
      "more-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer Profiel"])},
      "more-profile-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze informatie kan iedereen zien."])}
    },
    "notification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificatie instellingen veranderen."])},
      "notification-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificatie Geluid"])},
      "notification-sound-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maakt een cool geluid om je aandacht te krijgen."])},
      "push-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push Notificatie"])},
      "push-notification-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krijg push notificaties als de app gesloten is."])}
    },
    "manage-emojis": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload je eigen mooie emojis gratis! Emojis moeten 3MB of minder zijn."])},
      "supported-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteunde bestanden zijn: png, jpg, gif"])},
      "add-emojis-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis Toevoegen"])}
    },
    "startup-options": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop App Opstart Opties"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze wijzigingen op te slaan, moet je de Nertivia desktop app downloaden."])},
      "open-on-startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nertivia Openen Bij Het Opstarten."])}
    },
    "program-activity": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deel programma's of games die je open hebt door ze hier onder toe te voegen. Dit vervangt je status als het programma geopend is totdat je het programma sluit."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om programmas te delen moet je de Nertivia desktop app hebben."])}
    },
    "manage-bots": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak bot gebruikers waarmee je met onze API een eigen mooie bot kan maken."])},
      "command-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak bot commando's waar gebruikers gemakkelijk alle commando's van je bot kunnen zien"])},
      "create-bot-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak Bot."])},
      "add-command-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg Commando Toe"])},
      "edit-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Bewerken"])},
      "edit-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commando's Bewerken"])},
      "create-invite-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitatie Link Maken"])},
      "copy-invite-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitatie URL Kopiëren"])},
      "manage-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Beheren"])},
      "show-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat Token Zien"])},
      "copy-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Kopiëren"])},
      "reset-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Resetten"])},
      "delete-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Verwijderen"])}
    },
    "delete-account": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je staat op het punt je account te verwijderen"])},
      "what-gets-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat wordt verwijderd"])},
      "what-wont-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat niet wordt verwijderd"])},
      "messages-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je berichten verstuurd naar publieke servers"])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereisten"])},
      "delete-leave-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle servers verlaten/verwijderen"])},
      "delete-bots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bots verwijderen"])},
      "delete-account-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder Mijn Account"])}
    },
    "language": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misschien moet je opnieuw opstarten na het veranderen van je taal. Draag bij door een PR te maken in de GitHub repo."])},
      "translators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertalers"])}
    },
    "interface": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander het uiterlijk van Nertivia"])},
      "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleuren"])},
      "default-theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard Thema"])},
      "amoled-dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOLED Donker"])},
      "halloween-2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween 2020"])},
      "predefined-themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedefinieerde Thema's"])},
      "custom-css": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen CSS"])},
      "color-scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleuren Schema"])}
    },
    "wip-features": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zet onafgemaakte of experimentele functies aan"])},
      "none-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen WIP functies beschikbaar op dit moment"])}
    }
  },
  "server-settings": {
    "permissions": {
      "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemmingen"])},
      "admin": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeert alle toestemmingen"])}
      },
      "send-messages": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten Sturen"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur berichten in server kanalen"])}
      },
      "manage-roles": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen Beheren"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen updaten of verwijderen"])}
      },
      "manage-channels": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen Beheren"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen updaten of verwijderen"])}
      },
      "kick-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick een gebruiker"])}
      },
      "ban-user": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick & een gebruiker beperken om in de server te gaan"])}
      }
    },
    "tab-names": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "manage-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen Beheren"])},
      "manage-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen Beheren"])},
      "manage-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitaties Beheren"])},
      "server-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Zichtbaarheid"])},
      "manage-notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaties beheren"])},
      "banned-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbannen Gebruikers"])},
      "manage-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers Beheren"])},
      "delete-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Verwijderen"])}
    },
    "manage-roles": {
      "manage-your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jou Rollen Beheren"])},
      "default-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard Rol"])},
      "bot-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Rol"])},
      "create-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe Rol Maken"])},
      "notice-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze rol kan niet worden verwijderd/toegevoegd aan een lid omdat het gemaakt is door een bot. Verwijder de bot om deze rol te verwijderen."])},
      "notice-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze rol kan niet worden verwijderd of weg gehaald bij een lid omdat dit de standaard rol is dat elk lid krijgt."])},
      "pick-role-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies Rol Kleur"])},
      "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Naam"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
      "delete-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Verwijderen"])},
      "deleting-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol Aan Het Verwijderen..."])},
      "hide-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstop Rol"])}
    },
    "general": {
      "general-server-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene Server Instellingen"])}
    },
    "manage-channels": {
      "manage-your-channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalen beheren."])},
      "delete-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal Verwijderen"])},
      "deleting-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal Aan Het Verwijderen..."])},
      "channel-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal Naam"])},
      "default-channel-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een standaard kanaal. Je kan het veranderen in de Algemene pagina. Standaard kanalen kunnet niet worden verwijderd"])},
      "create-new-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw Kanaal Maken"])},
      "permission": {
        "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten Sturen"])}
      },
      "rate-limit-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelheid Limiet (seconde)"])},
      "text-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Kanaal"])},
      "html-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Kanaal (WIP)"])}
    },
    "banned-users": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unban members from here. Onverban leden via hier"])},
      "unbanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbannen..."])},
      "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onban"])}
    },
    "manage-invites": {
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only admins can see everyone's invites. Alleen admins kunnen iedereens invites zien"])},
      "create-invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak Invites"])},
      "custom-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen Invite"])},
      "save-custom-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigen Link Opslaan"])},
      "create-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Maken"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt Door"])},
      "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keren Gebruikt"])}
    },
    "manage-users": {
      "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek Gebruiker"])}
    },
    "delete-server": {
      "type-server-name-to-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ server naam om te bevestigen"])},
      "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen..."])}
    },
    "notifications": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander notificatie instellingen."])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze instellingen worden toegepast aan jou."])},
      "enable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaties Inschakelen"])},
      "mute-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demp Geluid"])},
      "disable-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaties Uitschakelen"])}
    }
  },
  "typing-status": {
    "is-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " is aan het typen..."])},
    "two-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " en ", _interpolate(_list(1)), " zijn aan het typen..."])},
    "three-are-typing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ", ", _interpolate(_list(1)), " en ", _interpolate(_list(2)), " zijn aan het typen..."])},
    "more-than-three": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " mensen aan het typen..."])}
  },
  "dm-tab": {
    "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrienden"])},
    "recents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente"])},
    "saved-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgeslagen Notities"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriend Toevoegen"])}
  },
  "dashboard-tab": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaties"])},
    "click-to-add-custom-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om eigen status toe te voegen"])},
    "caught-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helemaal Op De Hoogte!"])},
    "direct-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directe Berichten"])},
    "servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servers"])},
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verken"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "add-friend-popout": {
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een vriend toe door zijn naam en tag in te vullen"])},
    "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan Het Toevoegen..."])}
  },
  "add-server-popout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg Server Toe"])},
    "join-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treed toe aan een server met een invitatie code."])},
    "create-server-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak je eigen server. Start bij de naam."])},
    "tabs": {
      "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treed Toe"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak"])}
    }
  },
  "presence": {
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weg"])},
    "busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druk"])},
    "looking-to-play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op Zoek Om Te Spelen"])}
  },
  "right-drawer": {
    "server-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Leden (", _interpolate(_list(0)), ")"])},
    "server-offline-members": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Offline (", _interpolate(_list(0)), ")"])}
  },
  "profile-popout": {
    "common-friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeenschappelijke Vrienden"])},
    "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badges"])},
    "joined-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toe Treedt"])},
    "suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbannen"])},
    "remove-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriend Verwijderen"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzoek Accepteren"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzoek Annuleren"])},
    "add-friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriend Toevoegen"])},
    "send-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur Bericht"])},
    "common-servers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeenschappelijke Servers"])}
  },
  "connection": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar!"])},
    "authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticeren..."])}
  },
  "navbar": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Beschikbaar"])}
  },
  "generic": {
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd!"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])}
  },
  "messages": {
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heeft de server verlaten"])},
    "kicked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["werd gekickt"])},
    "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is verbannen"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ging de server in"])}
  },
  "could-not-connect-to-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kon niet verbinden met de server."])},
  "chat-area": {
    "rate-limit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snelheids Limiet Modus"])}
  }
}